body {
    background-color: #dddddd !important;
}

.menu-bar {
    position: absolute !important;
    z-index: 1 !important;
    margin-top: 20px !important;
    margin-left: 4px !important;
    padding: 0 !important;
    color: #fff !important;
}

.link {
    color: #1976d2;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}