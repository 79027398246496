body {
    font-family: 'Poppins', sans-serif !important;
}

.auth-container{
    height: 100vh;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.Mui-error {
    color: #d32f2f !important;
}



.Mui-error {
    color: #d32f2f !important;
}

.authBox {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
    border-radius: 15px !important;
    width: 570px !important;
    padding: 80px 80px 20px 80px !important;
    margin: 0 auto;
    margin-top: 0px !important;
    font-family: 'Poppins', sans-serif !important;

    .logo {
        width: 200px;
    }

    .title {
        margin: 15px 0;
        font-family: 'Poppins', sans-serif !important;
        line-height: 30px;
    }

    .links {
        font-size: 14px;
        margin-top: 25px;
        font-weight: 500;
        color: #0575E6;

        font-weight: 600 !important;
        cursor: pointer;
        font-family: 'Poppins', sans-serif !important;
    }

    .signup-links {
        font-size: 14px;
        margin-top: 25px;
        font-family: 'Poppins', sans-serif !important;

        .links {
            margin-left: 5px;
            font-family: 'Poppins', sans-serif !important;
        }
    }
    p{
        color: #6A7580 ;
        padding: 5px 0;
        margin-bottom: 20px !important;
        font-family: 'Poppins', sans-serif !important;

        &.MuiFormHelperText-contained {
            margin-bottom: 0 !important;
            padding: 5px 0 0 0;
            font-size: 14px !important;
            color: #B12525;
            font-weight: 500;
        }

        &.copyright {
            font-size: 12px;
            color: #697A8D;
            margin-bottom: 0 !important;
        }
    }

    button {
        border-radius: 99px;
        height: 50px;
        box-shadow: none;
        font-family: 'Poppins', sans-serif !important;
    }

    .MuiInputBase-formControl {
        border-radius: 99px;
        padding: 0 18px !important;
        font-family: 'Poppins', sans-serif !important;

        input {
            padding: 15px 0;
            font-family: 'Poppins', sans-serif !important;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: #bbb;
        }
    }

    .MuiFormControl-root {
        margin-bottom: 20px;
        font-family: 'Poppins', sans-serif !important;
    }

    legend {
        margin-left: 20px;
    }

    .MuiFormLabel-root {
        margin-left: 20px;
        font-family: 'Poppins', sans-serif !important;
    }

    @media only screen and (min-width:768px) and (max-width:1024px) {
        min-width: 100% !important;
    }

    @media only screen and (min-width:320px) and (max-width:767px) {
        min-width: 100% !important;
        padding: 30px 20px !important;

        .login-footer-links {
            display: flex;
            flex-direction: column;

            .MuiGrid-root {
                max-width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    text-align: center;
                    display: flex;
                }
            }

            .signup-links {
                font-size: 14px;
                margin-top: 10px;
                font-family: 'Poppins', sans-serif !important;

                .links {
                    display: inline-block;
                    margin-left: 5px;
                    font-family: 'Poppins', sans-serif !important;
                    margin-top: 5px;
                }
            }
        }
    }
}